<template>
  <v-app>
    <v-dialog v-model="shop_detail_dialog" scrollable max-width="1000px">
      <v-card style="padding: 20px; height: 100%" v-if="loggedinshop">
        <v-row>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2"
                ><v-img max-height="30" max-width="30" :src="require('@/assets/images/avatars/1.png')"></v-img>
                &nbsp;User Details</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text v-if="loggedinshop.user">
                <p class="font-18"><strong>Name: </strong>{{ loggedinshop.user.name }} ({{ loggedinshop.user.id }})</p>
                <p class="font-18"><strong>Username: </strong>{{ loggedinshop.user.username }}</p>
                <p class="font-18"><strong>Email: </strong>{{ loggedinshop.user.email }}</p>
                <p class="font-18"><strong>Shopify Cus. Id: </strong>{{ loggedinshop.user.shopify_customer_id }}</p>
                <p class="font-18"><strong>Registered Date: </strong>{{ loggedinshop.user.registerDate | date_format }}</p>
                <p class="font-18">
                  <strong>Last Visit: </strong>{{ loggedinshop.user.lastvisitDate | date_format('timeago') }}
                </p>
              </v-card-text>
              <v-card-text v-else>
                <div style="padding: 105px 0px" class="text-center">
                  <h3>User details not found!!</h3>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2"
                ><v-img max-height="30" max-width="30" :src="require('@/assets/images/logos/shopify.png')"></v-img>
                &nbsp;Shopify Shop </v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <p class="font-18"><strong>Specifier: </strong>{{ loggedinshop.specifier }}</p>
                <p class="font-18"><strong>Token: </strong>{{ loggedinshop.token }}</p>
                <p class="font-18">
                  <strong>Last Refreshed: </strong>{{ loggedinshop.last_feed_refresh | date_format('timeago') }}
                </p>
                <p class="font-18"><strong>Package Id: </strong>{{ loggedinshop.subscribed_package }}</p>
                <p class="font-18"><strong>Recurring Charge: </strong>${{ loggedinshop.subscribed_plan }}</p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="4">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                <v-img max-height="35" max-width="35" :src="require('@/assets/images/logos/etsy.png')"></v-img>
                &nbsp;Etsy Shop Details
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text v-if="loggedinshop.etsy_shop">
                <p class="font-18"><strong>Name: </strong>{{ loggedinshop.etsy_shop.name }}</p>
                <p class="font-18"><strong>Shop Owner: </strong>{{ loggedinshop.etsy_shop.shop_owner }}</p>
                <p class="font-18"><strong>Shop ID: </strong>{{ loggedinshop.etsy_shop.etsy_shop_id }}</p>
                <p class="font-18"><strong>Oauth Token: </strong>{{ loggedinshop.etsy_shop.oauth_token }}</p>
                <p class="font-18"><strong>Oauth Secret: </strong>{{ loggedinshop.etsy_shop.oauth_token_secret }}</p>
              </v-card-text>

              <v-card-text v-else>
                <div style="padding: 105px 0px" class="text-center">
                  <h3>Etsy Shop not Connected!!</h3>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <div v-if="loggedinshop" @click="showShopDetails" style="cursor: pointer">
            <strong>Shop logged in As: </strong> {{ loggedinshop.specifier }} ({{ loggedinshop.id }})
            <!-- <v-btn color="info" class="tet-white" small>Log Out</v-btn> -->
          </div>
          <v-spacer></v-spacer>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; 2021
            <a href="https://dpl.company" class="text-decoration-none" target="_blank">Digital Product Labs</a></span
          >
          <span class="d-sm-inline d-none">
            <a href="https://jeevandhakal.com.np" target="_blank" class="me-6 text--secondary text-decoration-none"
              >Developed proudly by Jeevan Dhakal</a
            >
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import store from '@/store'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },

  data() {
    // const isDrawerOpen = ref(null)
    return {
      isDrawerOpen: true,
      loggedinshop: null,
      shop_detail_dialog: false,
      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },

  mounted() {
    this.loggedinshop = store.getters.loggedInShop
  },

  methods: {
    showShopDetails() {
      this.shop_detail_dialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
