import Vue from "vue";
import moment from "moment";

Vue.filter("truncate", function (value, limit, trailing = false) {
  let string = value.substring(0, limit);

  if (value.length > limit && trailing) {
    string = string + '...'
  }

  return string;
});

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  let arr = value.split(" ");
  let capitalized_array = [];
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(" ");
});

Vue.filter("date_format", function (date, type = "YYYY-MM-DD", is_unix = false) {
  let moment_time;
  if (is_unix) {
    moment_time = moment.unix(date)
  } else {
    moment_time = moment(date);
  }
  if (type === "timeago") return moment_time.fromNow();
  if (type === "calendar") return moment_time.calendar();
  return moment_time.format(type);
});
