//store/modules/auth.js

import axios from 'axios';
const state = {
  user: null,
  loggedInShop: null
};
const getters = {
  isAuthenticated: state => state.user != null ? true : false,
  StateUser: state => state.user,
  loggedInShop: state => state.loggedInShop
};
const actions = {
  async LogIn({ commit }, User) {
    let response = await axios.post('admin/authenticate', User)
    if (response.data.userData) {
      localStorage.setItem("accessToken", response.data.accessToken)
      await commit('setUser', response.data.userData)
      return {
        success: true
      }
    } else {
      return {
        success: false
      }
    }
  },

  async LogOut({ commit }) {
    let user = null
    commit('LogOut', user)
    return {
      success: true
    }
  },

  async LoginAsClient({ commit }, ShopDetail) {
    await commit('setLoggedInShop', ShopDetail);
    return {
      success: true
    }
  },

  async LogOutShop({ commit }) {
    await commit('logOutShop');
    return {
      success: true
    }
  }
};
const mutations = {
  setUser(state, userData) {
    state.user = userData
  },
  LogOut(state) {
    state.user = null
  },
  logOutShop(state) {
    state.loggedInShop = null
  },
  setLoggedInShop(state, ShopDetail) {
    state.loggedInShop = ShopDetail
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
